import { Action, createReducer, on } from '@ngrx/store';
import {
  addBillChargeFailure,
  addBillChargeSuccess,
  addOptionalBillChargeFailure,
  addOptionalBillChargeSuccess,
  createBillCharge,
  createOptionalBillCharge,
  deleteBillCharge,
  deleteBillChargeFailure,
  deleteBillChargeSuccess,
  deleteInvoice,
  deleteInvoiceFailure,
  deleteInvoiceSuccess,
  getAllBilSettingSuccess,
  getAllBilinvloiceuccess,
  getAllBillinvloiceFailure,
  getAllBillinvloicelist1,
  getBilSettingSuccess,
  getBilinvloiceuccess,
  getBillSettingFailure,
  getBillSettingslists,
  getBillinvloiceFailure,
  getBillinvloicelist1,
  getOptionalFailure,
  getOptionalSuccess,
  getOptionallist,
  resetState,
  resetInvoiceState,
  updateBillCharge,
  updateBillChargeFailure,
  updateBillChargeSuccess,
  updateInvoice,
  updateInvoiceFailure,
  updateInvoiceSuccess,
  updateOptionalBillCharge,
  updateOptionalBillChargeFailure,
  updateOptionalBillChargeSuccess,
  addInvoiceSuccess,
  resetInvoiceListState,
} from './billSettings.action';

export interface billSettingState {
  billSetting: any;
  getBillSettingResponse: any;
  createinvildBillsResponse: any;
  getBillinvloiceResponse: any;
  updateBillChargeResponse: any;
  updateOptionalBillChargeResponse: any;
  getOptionalResponse: any;
  createBillChargeResponse: any;
  deleteBillChargeResponse: any;
  deleteInvoiceResponse: any;
  getAllBillinvloiceResponse: any;
  getAllBillSettingResponse: any;
  updateInvoiceResponse: any;
  createInvoiceResponse: any;
}

export const initialState: billSettingState = {
  billSetting: null,
  getBillSettingResponse: null,
  createinvildBillsResponse: null,
  getBillinvloiceResponse: null,
  updateBillChargeResponse: null,
  updateOptionalBillChargeResponse: null,
  getOptionalResponse: null,
  createBillChargeResponse: null,
  deleteBillChargeResponse: null,
  deleteInvoiceResponse: null,
  getAllBillinvloiceResponse: null,
  getAllBillSettingResponse: null,
  updateInvoiceResponse: null,
  createInvoiceResponse: null
};
export const BillSettingreducer = createReducer(
  initialState,
  on(getBillSettingslists, (state) => ({ ...state })),
  on(getBilSettingSuccess, (state, { getBillSettingResponse }) => ({
    ...state,
    getBillSettingResponse,
  })),
  on(getBillSettingFailure, (state, { getBillSettingError }) => ({
    ...state,
    getBillSettingError,
  })),

  on(getOptionallist, (state) => ({ ...state })),
  on(getOptionalSuccess, (state, { getOptionalResponse }) => ({
    ...state,
    getOptionalResponse,
  })),
  on(getOptionalFailure, (state, { getOptionalError }) => ({
    ...state,
    getOptionalError,
  })),

  on(getBillinvloicelist1, (state) => ({ ...state })),
  on(getBilinvloiceuccess, (state, { getBillinvloiceResponse }) => ({
    ...state,
    getBillinvloiceResponse,
  })),
  on(getBillinvloiceFailure, (state, { getBillinvloiceError }) => ({
    ...state,
    getBillinvloiceError,
  })),

  on(getAllBillinvloicelist1, (state) => ({ ...state })),
  on(getAllBilinvloiceuccess, (state, { getAllBillinvloiceResponse }) => ({
    ...state,
    getAllBillinvloiceResponse,
  })),
  on(getAllBillinvloiceFailure, (state, { getAllBillinvloiceError }) => ({
    ...state,
    getAllBillinvloiceError,
  })),

  on(updateBillCharge, (state) => ({ ...state })),
  on(updateBillChargeSuccess, (state, { updateBillChargeResponse }) => ({
    ...state,
    updateBillChargeResponse,
  })),
  on(updateBillChargeFailure, (state, { updateBillChargeError }) => ({
    ...state,
    updateBillChargeError,
  })),

  on(updateOptionalBillCharge, (state) => ({ ...state })),
  on(
    updateOptionalBillChargeSuccess,
    (state, { updateOptionalBillChargeResponse }) => ({
      ...state,
      updateOptionalBillChargeResponse,
    })
  ),
  on(
    updateOptionalBillChargeFailure,
    (state, { updateOptionalBillChargeError }) => ({
      ...state,
      updateOptionalBillChargeError,
    })
  ),

  on(createOptionalBillCharge, (state) => ({ ...state })),
  on(
    addOptionalBillChargeSuccess,
    (state, { createOptionalBillChargeResponse }) => ({
      ...state,
      createOptionalBillChargeResponse,
    })
  ),
  on(
    addOptionalBillChargeFailure,
    (state, { createOptionalBillChargeError }) => ({
      ...state,
      createOptionalBillChargeError,
    })
  ),

  on(
    addInvoiceSuccess,
    (state, { createInvoiceResponse }) => ({
      ...state,
      createInvoiceResponse,
    })
  ),



















  on(deleteBillCharge, (state) => ({ ...state })),
  on(deleteBillChargeSuccess, (state, { deleteBillChargeResponse }) => ({
    ...state,
    deleteBillChargeResponse,
  })),
  on(deleteBillChargeFailure, (state, { deleteBillChargeError }) => ({
    ...state,
    deleteBillChargeError,
  })),

  on(deleteInvoice, (state) => ({ ...state })),
  on(deleteInvoiceSuccess, (state, { deleteInvoiceResponse }) => ({
    ...state,
    deleteInvoiceResponse,
  })),
  on(deleteInvoiceFailure, (state, { deleteInvoiceError }) => ({
    ...state,
    deleteInvoiceError,
  })),

  on(createBillCharge, (state) => ({ ...state })),
  on(addBillChargeSuccess, (state, { createBillChargeResponse }) => ({
    ...state,
    createBillChargeResponse,
  })),
  on(addBillChargeFailure, (state, { createBillChargeError }) => ({
    ...state,
    createBillChargeError,
  })),
  on(getAllBilSettingSuccess, (state, { getAllBillSettingResponse }) => ({
    ...state,
    getAllBillSettingResponse,
  })),
  on(updateInvoice, (state) => ({ ...state })),
  on(updateInvoiceSuccess, (state, { updateInvoiceResponse }) => ({
    ...state,
    updateInvoiceResponse,
  })),
  on(updateInvoiceFailure, (state, { updateInvoiceError }) => ({
    ...state,
    updateInvoiceError,
  })),

  on(resetState, (state) => ({
    ...state,
    getAllBillSettingResponse: null // Resetting this specific property
  })),

  on(resetInvoiceState, (state) => ({
    ...state,
    getAllBillinvloiceResponse: null,
    getBillinvloiceResponse: null
    // Resetting this specific property
  })),

  on(resetInvoiceListState, (state) => ({
    ...state,
    getBillinvloiceResponse: null,
    // Resetting this specific property
  }))
  //
);
