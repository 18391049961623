// utilities.effects.ts
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import {
  CreateutilitiesFailure,
  Createutilitiesuccess,
  GetAllGasRateData,
  GetEvData,
  GetGasRateData,
  createutilities,
  getAllGasRateDataSuccess,
  getEvDataFailure,
  getEvDataSuccess,
  getGasRateDataFailure,
  getGasRateDataSuccess,
  getutilities,
  getutilitiesuccess,
} from './utilities.actions';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { UmdService } from '../../core/services/umd.services';
import {
  getElectricityRateDataFailure,
  getElectricityRateDataSuccess,
  GetElectricityRateData,
} from './utilities.actions';
import {
  GetWaterRateData,
  getWaterRateDataSuccess,
  getWaterRateDataFailure,
} from './utilities.actions';
import {
  getutilitiesFailure,
  getAllGasRateDataFailure,
} from './utilities.actions';

@Injectable()
export class UtilitiesEffects {
  createutilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createutilities),
      exhaustMap((action) => {
        this.loaderService.setLoading(true);
        return this.umdservice.createutilities(action.utilitiesData).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return Createutilitiesuccess({
                createutilitiesResponse1: response,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return CreateutilitiesFailure({ createutilitiesError: response });
            }
          }),
          catchError((error) => {
            this.sharedService.showMessageDialog(
              error.error.message,
              'Service Error',
              SweetAlertIcon.ERROR
            );
            return of(CreateutilitiesFailure({ createutilitiesError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getutilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getutilities),
      exhaustMap((action) => {
        this.loaderService.setLoading(true);
        return this.umdservice.getutilities(action.projectId).pipe(
          map((response: any) => {
            // this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              // this.sharedService.showMessageDialog(
              //   'Success',
              //   response.message,
              //   SweetAlertIcon.SUCCESS
              // );
              return getutilitiesuccess({
                getutilitiesResponse: response,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              this.loaderService.setLoading(false);
              return getutilitiesFailure({ getutilitiesError: response });
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              'Service Error',
              SweetAlertIcon.ERROR
            );
            return of(CreateutilitiesFailure({ createutilitiesError: error }));
          }),
          // finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  GetGasRateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetGasRateData),
      exhaustMap((unitRatePayload) => {
        this.loaderService.setLoading(true);
        return this.umdservice.getutilitiesRateHistory(unitRatePayload).pipe(
          map((response: any) => {
            // this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              return getGasRateDataSuccess({
                getGasRateDataResponse: response.data,
              });
            } else {
              this.loaderService.setLoading(false);
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return getGasRateDataFailure({ getGasRateDataError: response });
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              'Service Error',
              SweetAlertIcon.ERROR
            );
            return of(getGasRateDataFailure({ getGasRateDataError: error }));
          }),
          // finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  GetWaterRateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetWaterRateData),
      exhaustMap((unitRatePayload) => {
        this.loaderService.setLoading(true);
        return this.umdservice.getutilitiesRateHistory(unitRatePayload).pipe(
          map((response: any) => {
            //  this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              return getWaterRateDataSuccess({
                getWaterRateDataResponse: response.data,
              });
            } else {
              this.loaderService.setLoading(false);
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return getWaterRateDataFailure({
                getWaterRateDataError: response,
              });
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              'Service Error',
              SweetAlertIcon.ERROR
            );
            return of(
              getWaterRateDataFailure({ getWaterRateDataError: error })
            );
          }),
          //finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  GetElectricityRateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetElectricityRateData),
      exhaustMap((unitRatePayload) => {
        this.loaderService.setLoading(true);
        return this.umdservice.getutilitiesRateHistory(unitRatePayload).pipe(
          map((response: any) => {
            // this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              return getElectricityRateDataSuccess({
                getElectricityRateDataResponse: response.data,
              });
            } else {
              GetElectricityRateData
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return getElectricityRateDataFailure({
                getElectricityRateDataError: response,
              });
            }
          }),
          catchError((error) => {
            GetElectricityRateData
            this.sharedService.showMessageDialog(
              error.error.message,
              'Service Error',
              SweetAlertIcon.ERROR
            );
            return of(
              getElectricityRateDataFailure({
                getElectricityRateDataError: error,
              })
            );
          }),
          // finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  GetAllGasRateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllGasRateData),
      exhaustMap((unitRatePayload) => {
        this.loaderService.setLoading(true);
        return this.umdservice.getutilitiesRateHistory(unitRatePayload).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              return getAllGasRateDataSuccess({
                getAllGasRateDataResponse: response.data,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return getAllGasRateDataFailure({
                getAllGasRateDataError: response,
              });
            }
          }),
          catchError((error) => {
            this.sharedService.showMessageDialog(
              error.error.message,
              'Service Error',
              SweetAlertIcon.ERROR
            );
            return of(getGasRateDataFailure({ getGasRateDataError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );


  GetEvData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEvData),
      exhaustMap((unitRatePayload) => {
        this.loaderService.setLoading(true);
        return this.umdservice.getutilitiesRateHistory(unitRatePayload).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              return getEvDataSuccess({
                getEvDataResponse: response.data,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return getEvDataFailure({
                getEvDataError: response,
              });
            }
          }),
          catchError((error) => {
            this.sharedService.showMessageDialog(
              error.error.message,
              'Service Error',
              SweetAlertIcon.ERROR
            );
            return of(
              getElectricityRateDataFailure({
                getElectricityRateDataError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private umdservice: UmdService,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) { }
}
