import { Action, createReducer, on } from '@ngrx/store';
import {
  adduserlistSuccess,
  createUser,
  adduserlistFailure,
  deleteuserlistSuccess,
  getuserlist,
  getuserlistFailure,
  getuserlistSuccess,
  updateuserlistSuccess,
  getAlluserlist,
  getAlluserlistSuccess,
  getAlluserlistFailure,
  updateuserlist,
  updateuserlistFailure,
  getUserStstusChnage,
  getUserStatusSuccess,
  getUserSttausrFail,
  resetUserState,
  resetState,
  resetCreateState,
} from './userlist.action';
import { userListModel } from './userlist.model';
export interface UserlistState {
  users: userListModel | null;
  createUserError: any;
  getUserResponse: any;
  createUserResponse: any;
  getAllUserResponse: any;
  updateUserResponse: any;
  getuserStatusResponse: any;
}

export const initialState: UserlistState = {
  users: null,
  getUserResponse: null,
  createUserResponse: null,
  createUserError: null,
  getAllUserResponse: null,
  updateUserResponse: null,
  getuserStatusResponse: null,
};

export const UserListReducer = createReducer(
  initialState,
  on(getuserlist, (state) => ({ ...state })),
  on(createUser, (state) => ({ ...state, error: null })),
  on(adduserlistSuccess, (state, { createUserResponse }) => ({
    ...state,
    createUserResponse,
  })),
  on(adduserlistFailure, (state, { createUserError }) => ({
    ...state,
    createUserError,
  })),
  on(getuserlistSuccess, (state, { getUserResponse }) => ({
    ...state,
    getUserResponse,
  })),
  on(getuserlistFailure, (state, { getUserError }) => ({
    ...state,
    getUserError,
  })),

  on(getAlluserlist, (state) => ({ ...state })),
  on(getAlluserlistSuccess, (state, { getAllUserResponse }) => ({
    ...state,
    getAllUserResponse,
  })),

  on(getAlluserlistFailure, (state, { getAllUserError }) => ({
    ...state,
    getAllUserError,
  })),

  on(updateuserlist, (state) => ({ ...state })),
  on(updateuserlistSuccess, (state, { updateUserResponse }) => ({
    ...state,
    updateUserResponse,
  })),

  on(updateuserlistFailure, (state, { updateUserError }) => ({
    ...state,
    updateUserError,
  })),

  on(getUserStstusChnage, (state) => ({ ...state })),
  on(getUserStatusSuccess, (state, { getuserStatusResponse }) => ({
    ...state,
    getuserStatusResponse,
  })),
  on(getUserSttausrFail, (state, { getUserStatusError }) => ({
    ...state,
    getUserStatusError,
  })),

  on(resetState, (state) => ({
    ...state,
    getAllUserResponse: null,
    // Resetting this specific property
  })),

  on(resetCreateState, (state) => ({
    ...state,
    createUserResponse: null,
    // Resetting this specific property
  })),


  on(resetUserState, (state) => ({
    ...state,
    getUserResponse: null,
    // Resetting this specific property
  }))



);
