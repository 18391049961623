import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrudService } from '../../core/services/crud.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { map, catchError, exhaustMap } from 'rxjs/operators';

import { userListModel } from '../../../app/store/UserList/userlist.model';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { SweetAlertIcon } from '../../shared/constants/enum';

import {
  getuserlist,
  getuserlistSuccess,
  getuserlistFailure,
  adduserlistFailure,
  adduserlistSuccess,
  createUser,
  updateuserlistFailure,
  updateuserlistSuccess,
  updateuserlist,
  deleteuserlistFailure,
  deleteuserlistSuccess,
  deleteuserlist,
  getAlluserlist,
  getAlluserlistSuccess,
  getAlluserlistFailure,
  getUserStstusChnage,
  getUserSttausrFail,
  getUserStatusSuccess,
  // getuserlistById,
  // getuserlistByIdSuccess,
  // getuserlistByIdFailure
} from './userlist.action';

import { UserService } from '../../core/services/user.service';
import Swal from 'sweetalert2';

@Injectable()
export class userslistEffects {
  ServiceError = Validations.ServiceError;

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUser),
      exhaustMap((userData) => {
        console.log(userData);
        this.loaderService.setLoading(true);
        setTimeout(() => { });
        //
        return this.usersService.createUsers(userData.userData).pipe(
          map((response: any) => {
            console.log(response);
            //  this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              this.router.navigate(['/users']);
              return adduserlistSuccess({ createUserResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return adduserlistFailure({ createUserError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              '',
              SweetAlertIcon.ERROR
            );
            return of(adduserlistFailure({ createUserError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateuserlist),
      exhaustMap((userData, id) => {
        console.log(userData, '-----', id);
        this.loaderService.setLoading(true);
        return this.usersService
          .updateUser(userData.updatedData, userData.id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                const swalWithBootstrapButtons = Swal.mixin({
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-primary ms-2',
                  },
                  buttonsStyling: false,
                });
                swalWithBootstrapButtons
                  .fire({
                    allowOutsideClick: false,
                    title: response.message,
                    icon: SweetAlertIcon.SUCCESS,
                    showCancelButton: false,
                  })
                  .then((result) => {
                    if (result.value) {
                      this.router.navigate(['/users']);
                    }
                  });
                // return updateuserlistSuccess({ updateUserResponse: response })
                /*  setTimeout(() => {
                   this.router.navigate(['/users']);
                 }, 3000) */
                //this.router.navigate(['/users']);
                return getuserlist({
                  pageIndex: 1,
                  pageSize: 10,
                  searchTerm: '',
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateuserlistFailure({ updateUserError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(updateuserlistFailure({ updateUserError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getUserStstusChnage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserStstusChnage),
      exhaustMap(({ id, status }) => {
        return this.usersService.UserupdateStatusUrl(id, status).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return getUserStatusSuccess({
                getuserStatusResponse: response.data,
              });
            } else {
              return getUserSttausrFail({
                getUserStatusError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(getUserSttausrFail({ getUserStatusError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );
  /* getuserlistById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getuserlistById),
            exhaustMap((id) => {
                this.loaderService.setLoading(true);
                return this.usersService.getUserById(id).pipe(
                    map((response: any) => {
                        this.loaderService.setLoading(false);
                        console.log(response);
                        if (response && response.statusCode === 200) {
                            return getuserlistByIdSuccess({ getUsersByIdResponse: response.data });
                        } else {
                            return getuserlistByIdFailure({ getUsersByIdError: response });
                        }
                    }),
                    catchError((error) => {
                        console.log(error);
                        this.sharedService.showMessageDialog(error.error.message, this.ServiceError.Error, SweetAlertIcon.ERROR);
                        return of(getuserlistByIdFailure({ getUsersByIdError: error }));
                    }), finalize(() => this.loaderService.setLoading(false))
                );
            })
        )
    );
 */

  getuserlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getuserlist),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.usersService
          .getUsers(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {

              console.log(response);
              if (response && response.statusCode === 200) {
                this.loaderService.setLoading(false);
                return getuserlistSuccess({ getUserResponse: response.data });
              } else {
                this.loaderService.setLoading(false);
                return getuserlistFailure({ getUserError: response });
              }
            }),
            catchError((error) => {
              this.loaderService.setLoading(false);
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getuserlistFailure({ getUserError: error }));
            }),
            // finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAlluserlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAlluserlist),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.usersService
          .getUsers(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAlluserlistSuccess({
                  getAllUserResponse: response.data,
                });
              } else {
                return getAlluserlistFailure({ getAllUserError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getAlluserlistFailure({ getAllUserError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deleteuserlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteuserlist),
      exhaustMap((userData, id) => {
        console.log(userData, '-----', id);
        this.loaderService.setLoading(true);
        return this.usersService.deleteUser(userData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              // return deleteMasterSuccess({ deleteMasterResponse: response })
              return getuserlist({
                pageIndex: 1,
                pageSize: 10,
                searchTerm: '',
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteuserlistFailure({ deleteUserError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteuserlistFailure({ deleteUserError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private CrudService: CrudService,
    private usersService: UserService,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) { }
}
