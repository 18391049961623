import { createReducer, on } from '@ngrx/store';
import { Master } from './projects.models';
import {
  updateProjectSuccess,
  resetUpdateProjectResponse,
  resetProjectState, resetgetProjectState,
  getProjectDataSuccess,
} from './projects.actions';
import {
  GetProjects,
  deleteProjectSuccess,
  getAllProjectsFailure,
  getAllProjectsSuccess,
  getProjectsFailure,
  getProjectsSuccess,
} from './projects.actions';

export interface ProjectState {
  master: Master | null;
  createMasterError: any;
  getProjectsResponse: any;
  createMasterResponse: any;
  deleteProjectResponse: any;
  getAllProjectsResponse: any;
  getAllProjectsError: any;
  updateProjectResponse: any;
  getProjectDataSuccessResponse: any
}

const initialState: ProjectState = {
  master: null,
  createMasterError: null,
  getProjectsResponse: null,
  createMasterResponse: null,
  deleteProjectResponse: null,
  getAllProjectsResponse: null,
  getAllProjectsError: null,
  updateProjectResponse: null,
  getProjectDataSuccessResponse: null,
};

export const ProjectsReducer = createReducer(
  initialState,
  on(GetProjects, (state) => ({ ...state })),
  // on(createMaster, state => ({ ...state, error: null })),
  // on(CreateMasterSuccess, (state, { createMasterResponse }) => ({ ...state, createMasterResponse })),
  // on(CreateMasterFailure, (state, { createMasterError }) => ({ ...state, createMasterError })),
  on(getProjectsSuccess, (state, { getProjectsResponse }) => ({
    ...state,
    getProjectsResponse,
  })),
  on(getProjectsFailure, (state, { getProjectsError }) => ({
    ...state,
    getProjectsError,
  })),
  on(getAllProjectsSuccess, (state, { getAllProjectsResponse }) => ({
    ...state,
    getAllProjectsResponse,
  })),
  on(getAllProjectsFailure, (state, { getAllProjectsError }) => ({
    ...state,
    getAllProjectsError,
  })),
  on(deleteProjectSuccess, (state, { deleteProjectResponse }) => ({
    ...state,
    deleteProjectResponse,
  })),
  on(updateProjectSuccess, (state, { updateProjectResponse }) => ({
    ...state,
    updateProjectResponse,
  })),
  on(resetUpdateProjectResponse, (state) => ({
    ...state,
    updateProjectResponse: null,
  })),

  on(getProjectDataSuccess, (state, { getProjectDataSuccessResponse }) => ({
    ...state,
    getProjectDataSuccessResponse,
  })),
  on(resetProjectState, (state) => ({
    ...state,
    getAllProjectsResponse: null,
    // Resetting this specific property
  })),

  on(resetgetProjectState, (state) => ({
    ...state,
    getProjectsResponse: null,
    // Resetting this specific property
  }))
);
