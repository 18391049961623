// Umds.effects.ts
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { UmdService } from '../../core/services/umd.services';
import {
  updateUmdFailure,
  deleteUmduccess,
  GetElectricityStatus,
  GetElectricitySuccessStatus,
  GetElectricityFailureStatus,
  getAllUmdFailure,
  getAllUmdSuccess,
  GetAllUmd,
  GetAllElectricityFailureStatus,
  GetAllElectricitySuccessStatus,
  GetAllElectricityStatus,
} from './umd.actions';
import {
  CreateUmdFailure,
  CreateUmdSuccess,
  createUmd,
  updateUmd,
} from './umd.actions';
import {
  GetUmd,
  deleteUmd,
  getUmdFailure,
  getUmdSuccess,
  deleteUmdFailure,
} from './umd.actions';
@Injectable()
export class UmdModuleEffects {
  ServiceError = Validations.ServiceError;
  createUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUmd),
      exhaustMap((UmdData) => {
        console.log(UmdData);
        this.loaderService.setLoading(true);
        return this.umdService.createUmds(UmdData.UmdData).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return CreateUmdSuccess({ createUmdResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return CreateUmdFailure({ createUmdError: response });
            }
          }),
          catchError((error) => {
            console.log(error);

            this.sharedService.showMessageDialog(
              error.error.message,
              '',
              SweetAlertIcon.ERROR
            );
            return of(CreateUmdFailure({ createUmdError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUmd),
      exhaustMap((UmdData, id) => {
        console.log(UmdData, '-----', id);
        this.loaderService.setLoading(true);
        return this.umdService.updateUmd(UmdData.UmdData, UmdData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              // return updateUmdSuccess({ updateUmdResponse: response })
              return GetUmd({
                pageIndex: 1,
                pageSize: 10,
                searchTerm: '',
                projectId: UmdData?.UmdData?.projectId,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return updateUmdFailure({ updateUmdError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              '',
              SweetAlertIcon.ERROR
            );
            return of(CreateUmdFailure({ createUmdError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  deleteUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUmd),
      exhaustMap((UmdData, projectId) => {
        console.log(UmdData, '-----', projectId);
        this.loaderService.setLoading(true);
        return this.umdService.deleteUmd(UmdData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteUmduccess({ deleteUmdResponse: response });
              // return GetUmd({
              //   pageIndex: 1,
              //   pageSize: 10,
              //   searchTerm: '',
              //   projectId: UmdData.projectId,
              // });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteUmdFailure({ deleteUmdError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteUmdFailure({ deleteUmdError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUmd),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.umdService
            .getUmds(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                // this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getUmdSuccess({ getUmdResponse: response.data });
                } else {
                  this.loaderService.setLoading(false);
                  return getUmdFailure({ getUmdError: response });
                }
              }),
              catchError((error) => {
                this.loaderService.setLoading(false);
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getUmdFailure({ getUmdError: error }));
              }),
              //finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getAllUmd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllUmd),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.umdService
            .getUmds(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllUmdSuccess({ getAllUmdResponse: response.data });
                } else {
                  return getAllUmdFailure({ getAllUmdError: response });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getAllUmdFailure({ getAllUmdError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  GetElectricityStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetElectricityStatus),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.umdService
            .getelectricitystatus(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                //this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return GetElectricitySuccessStatus({
                    getElectricitySuccessResponse: response.data,
                  });
                } else {
                  this.loaderService.setLoading(false);
                  return GetElectricityFailureStatus({
                    getElectricityFailureResponse: response,
                  });
                }
              }),
              catchError((error) => {
                this.loaderService.setLoading(false);
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  GetElectricityFailureStatus({
                    getElectricityFailureResponse: error,
                  })
                );
              }),
              // finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  GetAllElectricityStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllElectricityStatus),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.umdService
            .getelectricitystatus(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return GetAllElectricitySuccessStatus({
                    GetAllElectricitySuccessResponse: response.data,
                  });
                } else {
                  return GetAllElectricityFailureStatus({
                    GetAllElectricityFailureResponse: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  GetAllElectricityFailureStatus({
                    GetAllElectricityFailureResponse: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private umdService: UmdService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) { }
}
