import {
  GetAllMeterDetails,
  GetAllmeter,

  Getmeter,
  checkMeterStatus,
  checkMeterStatusFailure,
  checkMeterStatusSuccess,
  geAdminmeteronOffHistoryExportFailure,
  geAdminmeteronOffHistoryExportSuccess,
  geAdminmeteronOffHistoryFailure,
  geAdminmeteronOffHistorySuccess,
  getAdminmeteronOffHistory,
  getAdminmeteronOffHistoryExport,
  getAllmeterConsumptionSuccess,
  getAllmeterFailure,
  getAllmeterSuccess,
  getMeterDetails,
  getmeterFailure,
  getmeterSuccess,
  updateMeterStatus,
  updateMeterStatusFailure,
  updateMeterStatusSuccess,
} from './meters.actions';
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { MeterService } from '../../core/services/meter.services';
import { getAllmeterConsumptionFailure } from './meters.actions';
import {
  getmeterConsumptionSuccess,
  getmeterConsumptionFailure,
} from './meters.actions';
@Injectable()
export class meterModuleEffects {
  ServiceError = Validations.ServiceError;

  getMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Getmeter),
      exhaustMap(({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
        this.loaderService.setLoading(true);
        return this.meterervice
          .getMeters(pageIndex, pageSize, searchTerm, projectId, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getmeterSuccess({
                  getmeterConfigResponse: response.data,
                });
              } else {
                return getmeterFailure({ getmeterError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getmeterFailure({ getmeterError: error }));
            }),
            finalize(() => {
              // Dismiss the loader here, but ensure UI is ready first
              setTimeout(() => {
                this.loaderService.setLoading(false);
              }, 100); // Let Angular rendering complete before stopping loader
            })
          );
      })
    )
  );


  getAllMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllmeter),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.meterervice
            .getMeters(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllmeterSuccess({
                    getAllmeterConfigResponse: response.data,
                  });
                } else {
                  return getAllmeterFailure({ getAllmeterError: response });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getAllmeterFailure({ getAllmeterError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getMeterConsumption$ = createEffect(() =>

    this.actions$.pipe(
      ofType(getMeterDetails),
      exhaustMap(
        ({
          pageIndex,
          pageSize,
          searchTerm,
          filter,
          MeterId,
          FromDate,
          ToDate,
          orderBy,
        }) => {
          console.log(filter)

          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.meterervice
            .getMasterConsumptions(
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              MeterId,
              FromDate,
              ToDate,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log("meter Readings ", response);
                if (response && response.statusCode === 200) {
                  return getmeterConsumptionSuccess({
                    getmeterConsumptionResponse: response.data,
                  });
                } else {
                  this.loaderService.setLoading(false);
                  return getmeterConsumptionFailure({
                    getmeterConsumptionError: response,
                  });
                }
              }),
              catchError((error) => {
                this.loaderService.setLoading(false);
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getmeterConsumptionFailure({
                    getmeterConsumptionError: error,
                  })
                );
              }),
              // finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getAdminmeteronOffHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminmeteronOffHistory),
      exhaustMap(
        ({
          pageIndex,
          pageSize,
          searchTerm,
          filter,
          orderBy,
          MeterId,
          FromDate,
          ToDate,

        }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.meterervice
            .getConsumerMeterOnOffControlsUrl(
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy,
              MeterId,
              FromDate,
              ToDate,
            )
            .pipe(
              map((response: any) => {
                // this.loaderService.setLoading(false);
                console.log("meter Hostory ", response);
                if (response && response.statusCode === 200) {
                  return geAdminmeteronOffHistorySuccess({
                    getAdminmeteronOffHistoryResponse: response.data,
                  });
                } else {
                  this.loaderService.setLoading(false);
                  return geAdminmeteronOffHistoryFailure({
                    getAdminmeteronOffHistoryError: response,
                  });
                }
              }),
              catchError((error) => {
                this.loaderService.setLoading(false);
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  geAdminmeteronOffHistoryFailure({
                    getAdminmeteronOffHistoryError: error,
                  })
                );
              }),
              // finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getAdminmeteronOffHistoryExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminmeteronOffHistoryExport),
      exhaustMap(
        ({
          pageIndex,
          pageSize,
          searchTerm,
          filter,
          orderBy,
          MeterId,

        }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.meterervice
            .getConsumerMeterOnOffControlsUrl(
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy,
              MeterId,
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log("meter Hostory ", response);
                if (response && response.statusCode === 200) {
                  return geAdminmeteronOffHistoryExportSuccess({
                    getAdminmeteronOffHistoryExportResponse: response.data,
                  });
                } else {
                  return geAdminmeteronOffHistoryExportFailure({
                    getAdminmeteronOffHistoryExportError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  geAdminmeteronOffHistoryExportFailure({
                    getAdminmeteronOffHistoryExportError: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );



  GetAllMeterDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllMeterDetails),
      exhaustMap(({ pageIndex, pageSize, searchTerm, MeterId, FromDate, ToDate, filter, orderBy }) => {
        this.loaderService.setLoading(true);
  
        return this.meterervice
          .getMasterConsumptions(pageIndex, pageSize, searchTerm, filter, MeterId, FromDate, ToDate, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllmeterConsumptionSuccess({
                  getAllmeterConsumptionResponse: response.data,
                });
              } else {
                return getAllmeterConsumptionFailure({
                  getAllmeterConsumptionError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getAllmeterConsumptionFailure({ getAllmeterConsumptionError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );
  

  updateMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMeterStatus),
      exhaustMap((request, id) => {
        console.log(request, '-----', id);
        this.loaderService.setLoading(true);
        return this.meterervice.updateMeterStatus(request.request).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response) {

              console.log(request.request.status)
              this.sharedService.showMessageDialog(
                request.request.status == 'On' ? 'ON Request sent' : ' OFF Request sent.',
                response,
                SweetAlertIcon.SUCCESS
              );
              return updateMeterStatusSuccess({
                updateMeterStatusResponse: response,
              });
              // return Getmaster({pageIndex:1,pageSize:10,searchTerm:''})
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return updateMeterStatusFailure({
                updateMeterStatusError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              updateMeterStatusFailure({ updateMeterStatusError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  checkMeterStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkMeterStatus),
      exhaustMap((request) => {
        console.log(request, '-----');
        this.loaderService.setLoading(true);
        return this.meterervice.checkMeterStatus(request.request).pipe(
          map((response: any) => {
            // this.loaderService.setLoading(false);
            if (response) {
              return checkMeterStatusSuccess({
                checkMeterStatusResponse: response,
              });
              // return Getmaster({pageIndex:1,pageSize:10,searchTerm:''})
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return checkMeterStatusFailure({
                checkMeterStatusError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              checkMeterStatusFailure({ checkMeterStatusError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private meterervice: MeterService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) { }
}
