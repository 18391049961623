import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { TokenStorageService } from '../../core/services/token-storage.service';
import { LoaderService } from '../../core/services/loader.service';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { logout } from '../../store/Authentication/authentication.actions';
import { AuthenticationService } from '../services/auth.service';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';

const TOKEN_HEADER_KEY = 'Authorization'; // for Node.js Express back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  erroHandelExcpetion = [
    'The database operation was expected',
    'Object reference not set to an instance of an object',
    'Unable to establish a connection to the database server',
    'The database query execution failed due to a syntax error.',
    'Database transaction was aborted due to a constraint violation.',
    'Failed to fetch data from the database due to internal error.',
    'The database connection timed out. Please try again later.',
    ' Data is Null. This method or property cannot be called on Null values.',
    'Execution Timeout Expired',
    'The timeout period elapsed prior to completion',
    'Execution Timeout Expired',
    'UseSqlServer',
    'EnableRetryOnFailure'
  ];


  constructor(
    private token: TokenStorageService,
    private router: Router,
    private store: Store,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
  ) {


  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request has the 'No-Intercept' header

    let authReq = req;
    const token = this.token.getToken();
    const user = this.token.getUser();
    if (token) {
      if (req.headers.has('No-Intercept')) {
        const headers = req.headers.delete('No-Intercept');
        authReq = req.clone({ headers });
      } else {
        const headers = req.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`);

        if (req.headers.has('projectIdRequired')) {
          authReq = req.clone({
            headers: headers.set('projectId', user?.project?.id || ''),
          });
        } else {
          authReq = req.clone({ headers });
        }
      }
    }

    /*  return next.handle(authReq).pipe(
       catchError((error: HttpErrorResponse) => {
         if (error.status === 401 && error.statusText === 'Unauthorized') {
           // auto logout if 401 response returned from API
           console.log('error==>', error);
           this.authenticationService.apiError();
         }
 
         console.log('error==>', error.error.message);
 
         // Check if the error message contains any predefined exceptions
         const containsDatabase = this.erroHandelExcpetion.some(exception => error.error.message.includes(exception));
 
         if (containsDatabase) {
           console.log('Database-related error detected.', containsDatabase);
 
           // Show the error dialog
           this.sharedService.showMessageDialog(
             error.error.message,  // Show the actual error message
             "Something went wrong, please try again later.",
             SweetAlertIcon.ERROR
           );
 
           // Stop further execution and return the error observable
           return throwError(() => error);  // Ends the observable chain early
         }
 
         // If not a database-related error, just rethrow the error
         return throwError(() => error);
       })
     ); */

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check if the error status is 401 (Unauthorized)
        if (error.status === 401 && error.statusText === 'Unauthorized') {
          console.log('Unauthorized error:', error);
          // Handle 401 error (for example, log out)
          this.authenticationService.apiError();
        }

        // Log the error message to the console
        console.log('Error response:', error);

        // Check if the error message contains any of the database-related exceptions
        const containsDatabase = this.erroHandelExcpetion.some(exception =>
          error.error.message.includes(exception)
        );

        if (containsDatabase) {
          // If it's a database-related error, show the error message dialog
          console.log('Database-related error detected.');

          this.sharedService.showMessageDialog(
            '',  // Show the actual error message
            "Something went wrong, please try again later.",
            SweetAlertIcon.ERROR
          );
          //return;
          // Allow the observable chain to continue so that the user can retry
          // We use `throwError()` to propagate the error, but we ensure it doesn't stop the observable chain
          return throwError(() => new Error('Database error occurred'));  // Propagate error
        }

        // If it's a different type of error, propagate it normally
        return throwError(() => error);
      })
    );



  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
