import { NotificationService } from './../../shared/notification.service';
import { ProjectsService } from './../../core/services/projects.services';
import { UserProfile } from './../../shared/modals/common';
import {
  getNotificationList,
  getUserProfile,
  refreshToken,
} from './../../store/Authentication/authentication.actions';
import {
  getNewNotificationResponse,
  refreshTokenRes,
  userDetails,
} from './../../store/Authentication/authentication-selector';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/auth.service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { logout } from '../../store/Authentication/authentication.actions';
import { Store, select } from '@ngrx/store';
import { getError } from '../../store/Authentication/authentication-selector';
import { error, log } from 'console';
import { LoaderService } from '../../core/services/loader.service';
import { RpUserProfileComponent } from '../../features/users/rp-user-profile/rp-user-profile.component';
import { RpChangepasswordComponent } from '../../features/users/rp-changepassword/rp-changepassword.component';
import { SharedService } from '../../shared/shared.service';
import { NOTIFICATIONS_ICONS, notificationType, Roles, SweetAlertIcon } from '../../shared/constants/enum';
import { MessagingService } from '../../core/services/message.service';
import { ConsumerFeedbackComponent } from '../../features/feedback-history/rp-feedback/rp-feedback.component';
import { PaymentService } from '../../core/services/payment.service';
import Swal from 'sweetalert2';
import { resetHistoryState, resetState } from '../../store/Wallet/wallet.action';
import { HttpErrorResponse } from '@angular/common/http';
import { getProjectDataSuccessResponse } from '../../store/Projects/projects-selector';
import { getProject } from '../../store/Projects/projects.actions';
import { RpSupportComponent } from '../../features/rp-support/rp-support.component';

@Component({
  selector: 'app-rp-header',
  templateUrl: './rp-header.component.html',
  styleUrl: './rp-header.component.scss',
})
export class RpHeaderComponent implements OnInit {
  mode: any;
  element: any;
  cookieValue: any;
  sesstionexpiresTime: number = 0;
  cutoffTime: number = 5 * 60; // to show popup Message  for remainder
  lastActivityTime!: number; // login  time 
  token: any;
  refreshToken: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  submitted: boolean = false;
  error$: any;
  activeInstance: any;
  profileData: UserProfile | undefined;
  newNotifications: any;
  totalNotifications: number = 0;
  userRole: any;
  listOfRoles = Roles;
  notificationList: any[] = [];
  NOTIFICATIONS_ICONS = NOTIFICATIONS_ICONS;
  configData: any;
  projectDetails: any;
  Roles = Roles;
  user: any;
  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store,
    public languageService: LanguageService,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private paymentService: PaymentService,
    private projectsService: ProjectsService,
    private messagingService: MessagingService, // public activeModal: NgbActiveModal,
    private notificationService: NotificationService
  ) {
    this.getStoreInfo();
    this.resetTimer();
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.token = this.currentUserData.token;
    this.refreshToken = this.currentUserData.refreshToken;
    this.tokenExpiresOn = this.currentUserData.tokenExpiresOn;
    if (this.currentUserData) {
      this.sessionExpiredTimecalculate();
    }
  }

  gotoDashBoard() {
    console.log(this.currentUserData);
    const role = this.currentUserData?.currentUserRoleDetails?.role;

    switch (role) {
      case Roles.ADMIN:
        this.router.navigate(['/admindashboard']);
        break;
      case Roles.SUPERADMIN:
        this.router.navigate(['/dashboard']);
        break;
      case Roles.CONSUMER:
        this.router.navigate(['/consumerdashboard']);
        break;
      default:
        console.warn('Unknown role, no dashboard to navigate to.');
        return false;
    }

    return true;
  }

  resetTimer(): void {
    this.lastActivityTime = new Date().getTime(); //login Time
    console.log(this.lastActivityTime);
  }

  sessionExpiredTimecalculate() {

    //sesstion Time out
    let currentDateTime = new Date();
    let getDateTime = currentDateTime.getTime();

    const sessstionExpiresTime = new Date(this.tokenExpiresOn);
    //  let dt = new Date('2024-12-13T13:17:00')
    //  const sessstionExpiresTime = dt
    // console.log(sessstionExpiresTime, 'sessstionExpiresTime');
    const expireTime = Math.floor(sessstionExpiresTime.getTime()) - this.lastActivityTime;

    //   console.log(expireTime, 'expireTime');
    let sessstionExpireTimeInMillisecounds = expireTime / 1000;
    // console.log(sessstionExpireTimeInMillisecounds);
    let remainingTime = Math.floor(sessstionExpireTimeInMillisecounds);
    //  console.log(remainingTime, 'remaingTime');
    this.sesstionexpiresTime = remainingTime;
    // console.log(this.sesstionexpiresTime, 'SesstionexpireTime');
    if (this.sesstionexpiresTime > 0) {
      this.startTimer();
    } else {
      this.logout();
    }
  }

  timerView: string = '';
  private timeSubscription: any;
  private startTimer(): void {
    this.timeSubscription = setInterval(() => {
      if (this.sesstionexpiresTime > 0) {
        //  console.log(this.sesstionexpiresTime)
        // console.log(this.cutoffTime)
        if (this.cutoffTime == this.sesstionexpiresTime) {
          // console.log('Sesstion is Expired');
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-primary ms-2',
            },
            buttonsStyling: false,
          });

          swalWithBootstrapButtons
            .fire({
              allowOutsideClick: false,
              title: 'Session out',
              text: `Your Session is going to Expire in the next 5 minutes!.`,
              icon: 'warning',
              confirmButtonText: 'Yes, Continue',
              cancelButtonText: 'No, Cancel',
              showCancelButton: true,
              // timer: 20000, // close automatically after timeout
              timerProgressBar: true,
            })
            .then((result) => {
              console.log(result, 'result');
              if (result.value) {
                this.getrefreshToken();
              }
            });
        }

        this.sesstionexpiresTime--;
        this.cd.detectChanges();
      } else {
        clearInterval(this.timeSubscription);
        this.logout();
        console.log('Sesstion is Expired');
      }
    }, 1000);
  }

  private formateDigits(digits: number): string {
    return digits < 10 ? `0${digits}` : `${digits}`;
  }

  public formatTime(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(time % 3600) / 60;
    const secounds = time % 60;

    return `${this.formateDigits(hours)}:${this.formateDigits(
      minutes
    )}:${this.formateDigits(secounds)}`;
  }

  getStoreInfo() {
    this.error$ = this.store.pipe(select(getError));
    let profileData$ = this.store.pipe(select(userDetails));
    let getNewNotificationResponse$ = this.store.pipe(select(getNewNotificationResponse))
    let getProjectDataSuccessResponse$ = this.store.pipe(select(getProjectDataSuccessResponse));

    getProjectDataSuccessResponse$.subscribe((project: any) => {
      this.loaderService.setHeaderLoading(false);
      if (project) {

        if (project.statusCode == 200) {

          this.projectDetails = project.data;

        }
      }
    });


    this.error$.subscribe((res: any) => {
      console.log(res);
      this.loaderService.setHeaderLoading(false);
    });

    profileData$.subscribe((res: any) => {
      this.loaderService.setHeaderLoading(false);
      this.profileData = res;
      console.log('responseprofie', res);
    });


    getNewNotificationResponse$.subscribe((res: any) => {
      this.loaderService.setHeaderLoading(false);
      if (res && res.data) {
        this.notificationList = [...res.data]

        //         this.notificationList =[
        //           {
        //               "id": "5cfb6bb1-4470-4168-d3a5-08dcef6a63a4",
        //               "type": "Recharge_successful",
        //               "description": "Your recharge of ₹700 for Ripple account wallet is successful. Transaction ID: pay_PAUjRXG4B5GJOa. Check Ripple Web / Mobile for details.",
        //               "isRead": true,
        //               "isClear": false,
        //               "receivedOn": "24 minute(s) ago",
        //               "feedbackId": null,
        //               "feedbackName": null,
        //               "macAddress": null,
        //               "billNo": null,
        //               "invoiceNo": null,
        //               "paymentRefNo": "pay_PAUjRXG4B5GJOa"
        //           },
        //           {
        //               "id": "6c4cc101-619e-4269-d3a4-08dcef6a63a4",
        //               "type": "Recharge_successful",
        //               "description": "Your recharge of ₹500 for Ripple account wallet is successful. Transaction ID: pay_PAUMKfdQxnHZeH. Check Ripple Web / Mobile for details.",
        //               "isRead": true,
        //               "isClear": false,
        //               "receivedOn": "45 minute(s) ago",
        //               "feedbackId": null,
        //               "feedbackName": null,
        //               "macAddress": null,
        //               "billNo": null,
        //               "invoiceNo": null,
        //               "paymentRefNo": "pay_PAUMKfdQxnHZeH"
        //           },
        //           {
        //             "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //             "type": "Recharge_successful",
        //             "description": "Your recharge of ₹1000 for Ripple account wallet is successful. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //             "isRead": true,
        //             "isClear": false,
        //             "receivedOn": "15 day(s) ago",
        //             "feedbackId": "00000000-0000-0000-0000-000000000000",
        //             "feedbackName": "",
        //             "macAddress": "",
        //             "billNo": "",
        //             "invoiceNo": null,
        //             "paymentRefNo": "pay_P4X3ELuRjqkGSV"
        //         },
        //           {
        //               "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //               "type": "Meter_On_Off_Notification",
        //               "description": "Your recharge of ₹1000 for Ripple account wallet is successful. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //               "isRead": true,
        //               "isClear": false,
        //               "receivedOn": "15 day(s) ago",
        //               "feedbackId": "00000000-0000-0000-0000-000000000000",
        //               "feedbackName": "",
        //               "macAddress": "2C-9A-3C-66-70-CE",
        //               "billNo": "",
        //               "invoiceNo": null,
        //               "paymentRefNo": "pay_P4X3ELuRjqkGSV"
        //           },
        //           {
        //               "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //               "type": "Invoice_Generation",
        //               "description": "Your recharge of ₹1000 for Ripple account wallet is successful. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //               "isRead": true,
        //               "isClear": false,
        //               "receivedOn": "15 day(s) ago",
        //               "feedbackId": "00000000-0000-0000-0000-000000000000",
        //               "feedbackName": "",
        //               "macAddress": "2C-9A-3C-66-70-CE",
        //               "billNo": "",
        //               "invoiceNo": 'sdfsdf',
        //               "paymentRefNo": "pay_P4X3ELuRjqkGSV"
        //           },
        //           {
        //             "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //             "type": "Bill_Generation",
        //             "description": "Your recharge of ₹1000 for Ripple account wallet is successful. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //             "isRead": true,
        //             "isClear": false,
        //             "receivedOn": "15 day(s) ago",
        //             "feedbackId": "00000000-0000-0000-0000-000000000000",
        //             "feedbackName": "",
        //             "macAddress": "2C-9A-3C-66-70-CE",
        //             "billNo": "asdfasdf",
        //             "invoiceNo": 'sdfsdf',
        //             "paymentRefNo": "pay_P4X3ELuRjqkGSV"
        //         },
        //         {
        //           "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //           "type": "Bill_Payment_success",
        //           "description": "Your recharge of ₹1000 for Ripple account wallet is successful. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //           "isRead": true,
        //           "isClear": false,
        //           "receivedOn": "15 day(s) ago",
        //           "feedbackId": "00000000-0000-0000-0000-000000000000",
        //           "feedbackName": "",
        //           "macAddress": "2C-9A-3C-66-70-CE",
        //           "billNo": "asdfasdf",
        //           "invoiceNo": 'sdfsdf',
        //           "paymentRefNo": "pay_P4X3ELuRjqkGSV",
        //           "billNumber":'RP24100800015',
        //       },{
        //         "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //         "type": "Meter_MCB_Turned_On",
        //         "description": "Your recharge of ₹1000 for Ripple account wallet is successful. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //         "isRead": true,
        //         "isClear": false,
        //         "receivedOn": "15 day(s) ago",
        //         "feedbackId": "00000000-0000-0000-0000-000000000000",
        //         "feedbackName": "",
        //         "macAddress": "2C-9A-3C-66-70-CE",
        //         "billNo": "asdfasdf",
        //         "invoiceNo": 'sdfsdf',
        //         "paymentRefNo": "pay_P4X3ELuRjqkGSV",
        //         "billNumber":'RP24100800015',
        //     },{
        //       "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //       "type": "Low_Recharge_Alert",
        //       "description": "Low recharge alert test. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //       "isRead": true,
        //       "isClear": false,
        //       "receivedOn": "15 day(s) ago",
        //       "feedbackId": "00000000-0000-0000-0000-000000000000",
        //       "feedbackName": "",
        //       "macAddress": "2C-9A-3C-66-70-CE",
        //       "billNo": "asdfasdf",
        //       "invoiceNo": 'sdfsdf',
        //       "paymentRefNo": "pay_P4X3ELuRjqkGSV",
        //       "billNumber":'RP24100800015',
        //   },
        //   {
        //     "id": "c7405c45-7937-4be0-3b21-08dce395f638",
        //     "type": "High_Consumption_Alert_GAS",
        //     "description": "High_Consumption_Alert_GAS. Transaction ID: pay_P4X3ELuRjqkGSV. Check Ripple Web / Mobile for details.",
        //     "isRead": true,
        //     "isClear": false,
        //     "receivedOn": "15 day(s) ago",
        //     "feedbackId": "00000000-0000-0000-0000-000000000000",
        //     "feedbackName": "",
        //     "macAddress": "2C-9A-3C-66-70-CE",
        //     "billNo": "",
        //     "invoiceNo": '',
        //     "paymentRefNo": "",
        //     "billNumber":'',
        // }]


        this.totalNotifications = this.notificationList.filter(notification => !notification.isRead).length;


      }
      console.log('notificationList', res);
    });
  }

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,

  };

  listLang: any = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu!: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.modalRef = this.modalService.show(content);
  }

  getNotificationIcon(type: string): string {
    return this.NOTIFICATIONS_ICONS[type as keyof typeof NOTIFICATIONS_ICONS]?.url || '';
  }

  getNotificationColor(type: string): string {
    return this.NOTIFICATIONS_ICONS[type as keyof typeof NOTIFICATIONS_ICONS]?.color || '#2387C8';
  }

  getNotificationIconClass(type: string): string {
    return this.NOTIFICATIONS_ICONS[type as keyof typeof NOTIFICATIONS_ICONS]?.icon || 'bx bx-bell';
  }

  getRedirectUrl(type: string): string | null {
    const notificationType = NOTIFICATIONS_ICONS[type as keyof typeof NOTIFICATIONS_ICONS];
    return notificationType ? notificationType.redirectUrl : null;
  }


  clearAllNotifications() {
    this.loaderService.setHeaderLoading(true)
    this.authenticationService.clearNotifications().subscribe({
      next: (response) => {
        this.loaderService.setHeaderLoading(false)
        this.notificationList = [];
        this.totalNotifications = this.notificationList.length;
        this.closeNotificationPanel()
      },
      error: (error: HttpErrorResponse) => {
        this.loaderService.setHeaderLoading(false)
        this.sharedService.showMessageDialog(
          error.message,
          '',
          SweetAlertIcon.ERROR
        );
        console.error('Error occurred:', error);
      }
    });
  }

  readAllNotifications() {
    this.loaderService.setHeaderLoading(true)
    this.authenticationService.readAllNotifications().subscribe({
      next: (response) => {
        this.loaderService.setHeaderLoading(false)
        this.notificationList = this.notificationList.map(notification => {
          return { ...notification, isRead: true };
        });
        // this.notificationList = [];
        this.totalNotifications = 0
      },
      error: (error: HttpErrorResponse) => {
        this.loaderService.setHeaderLoading(false)
        this.sharedService.showMessageDialog(
          error.message,
          '',
          SweetAlertIcon.ERROR
        );
        console.error('Error occurred:', error);
      }
    });
  }


  openProfileModal() {
    let configData = this.configData;
    this.modalRef = this.modalService.show(RpUserProfileComponent, {
      initialState: { configData },
      class: 'modal-lg',

    });
  }

  
  openSupportModal() {
    console.log(this.currentUserData)
    let userData = this.currentUserData.project ;
    this.modalRef = this.modalService.show(RpSupportComponent, {
      initialState: { userData },
      class: 'modal-lg',

    });
  }

  openConsumer_FeedbackModal() {
    this.modalRef = this.modalService.show(ConsumerFeedbackComponent, {
      class: 'modal-lg',
    });
  }

  openChangePwdModal() {
    this.modalRef = this.modalService.show(RpChangepasswordComponent, this.config);
  }


  /**
   * Open center modal
   * @param centerDataModal center modal data
   */
  centerModal(centerDataModal: any) {
    this.modalRef = this.modalService.show(centerDataModal);
  }

  /**
   * Profile modal
   * @param profile center modal data
   */
  profileModal(profile: any) {
    this.modalRef = this.modalService.show(profile);
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const menu = document.querySelector('.menu'); // Adjust the selector if needed

    // Check if the click was outside the menu and its toggle button
    if (this.isMenuOpen && menu && !menu.contains(target) && !target.classList.contains('notificationmenu')) {
      this.isMenuOpen = false;
    }
  }


  ngOnInit() {
    this.openMobileMenu = false;
    this.getAllConfigData();
    this.getUserInfo();
    this.getFcmNotifications();
    this.getNotifications();

    if (
      this.userRole.toUpperCase() !== Roles.SUPERADMIN.toUpperCase() &&
      this.userRole.toUpperCase() !== Roles.SUPERUSER.toUpperCase()
    ) {
      this.getCurrentUserRegisterdProjectData();
    }

    if (this.currentUserData?.hasEmptyPassword) {
      this.modalRef = this.modalService.show(RpChangepasswordComponent,this.config);
    }
    //this.startTimer()
  }

  getCurrentUserRegisterdProjectData() {
    this.store.dispatch(getProject({ projectId: this.currentUserData?.project?.id }));
  }


  getAllConfigData(): void {
    this.loaderService.setHeaderLoading(true)
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data;
      }
    })
  }
  getFcmNotifications() {
    this.newNotifications = this.messagingService.getNewNotification;
    this.messagingService.getNewNotification().subscribe((user) => {
      if (user && user.notification) {
        this.totalNotifications = this.totalNotifications + 1;
      }
    });
  }
  // getNavUrl(data: any) {
  //   this.loaderService.setHeaderLoading(false)
  //   var url = '';    
  //   switch (data.type.toUpperCase()) {
  //     case NOTIFICATIONS_ICONS.Feedback.type.toUpperCase():
  //       url = '/feedbackHistory';
  //       this.router.navigate([url], {
  //         state: {
  //           from: 'notifications',
  //           info: data,
  //         },
  //       });
  //       this.closeNotificationPanel()
  //       break;
  //     case NOTIFICATIONS_ICONS.Recharge_successful.type.toUpperCase():
  //       url = '/rechargeHistory';

  //       if (this.currentUserData.project.billingType == 'Prepaid' || (this.currentUserData.project.billingType == 'Postpaid' && this.currentUserData.project.isWalletEnabled)) {
  //         this.router.navigate([url], {
  //           state: {
  //             from: 'notifications',
  //             info: data.paymentRefNo,
  //           },
  //         });
  //         this.closeNotificationPanel()
  //       }
  //       break;
  //     case NOTIFICATIONS_ICONS.Meter_On_Off_Notification.type.toUpperCase():
  //       url = '/meterContolls';
  //       this.router.navigate([url], { state: {
  //         from: 'notifications',
  //         info: data.macAddress,
  //       },});
  //       this.closeNotificationPanel();
  //       break;
  //     case NOTIFICATIONS_ICONS.Bill_Payment_success.type.toUpperCase():
  //       url = '/billHistory';
  //       this.router.navigate([url], {});
  //       this.closeNotificationPanel();
  //       break;

  //     case NOTIFICATIONS_ICONS.BILL.type.toUpperCase():
  //       url = '/postpaidBill';
  //       this.router.navigate([url], {});
  //       this.closeNotificationPanel();
  //       break;
  //     case NOTIFICATIONS_ICONS.INVOICE.type.toUpperCase():
  //       url = '/billInvoices';
  //       this.router.navigate([url], {});
  //       this.closeNotificationPanel();
  //       break;



  //     default:
  //       console.error('Unknown role');
  //       return;
  //   }
  // }


  getNavUrl(data: any) {
    this.loaderService.setHeaderLoading(false);

    // Convert the notification type to uppercase for consistent comparison
    const notificationType = data.type.toUpperCase();

    // Find the corresponding notification icon and its redirect URL
    const notificationIcon = Object.values(NOTIFICATIONS_ICONS).find(
      icon => icon.type.toUpperCase() === notificationType
    );

    // If the notification type is found
    if (notificationIcon) {
      let url = notificationIcon.redirectUrl;

      // Special cases for certain types like Recharge and Meter On/Off
      if (notificationType === NOTIFICATIONS_ICONS.Feedback.type.toUpperCase()) {
        if (this.router.url === url) {
          // Already on the Feedback page, update the state directly
          this.notificationService.notifyFeedbackUpdate(data);
        }
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: data
          }
        });
        this.closeNotificationPanel();
      }
      else if (notificationType === NOTIFICATIONS_ICONS.Recharge_successful.type.toUpperCase() || notificationType === NOTIFICATIONS_ICONS.Recharge_failed.type.toUpperCase()) {
        if (this.router.url === url) {
          // Already on the RechargePAge, update the state directly
          this.notificationService.notifyRechargeUpdate(data.paymentRefNo);
        }
        if (this.currentUserData.project.billingType === 'Prepaid' ||
          (this.currentUserData.project.billingType === 'Postpaid' && this.currentUserData.project.isWalletEnabled)) {
          this.router.navigate([url], {
            state: {
              from: 'notifications',
              info: data?.paymentRefNo ?? ''
            }
          });
          this.closeNotificationPanel();
        }
      } else if (notificationType === NOTIFICATIONS_ICONS.Meter_On_Off_Notification.type.toUpperCase()) {
        if (this.router.url === url) {
          // Already on the Meter controller, update the state directly
          this.notificationService.notifyMeterOnOffUpdate(data.macAddress);
        }
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: data?.macAddress ?? ''
          }
        });
        this.closeNotificationPanel();
      }
      else if (notificationType === NOTIFICATIONS_ICONS.Invoice_Generation.type.toUpperCase()) {
        if (this.router.url === url) {
          // Already on the Meter controller, update the state directly
          this.notificationService.notifyInvoiceGeneratiorUpdate(data?.invoiceNo);
        }
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: data?.invoiceNo ?? ''
          }
        });
        this.closeNotificationPanel();
      }
      else if (notificationType === NOTIFICATIONS_ICONS.Bill_Generation.type.toUpperCase()) {
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: ''
          }
        });
        this.closeNotificationPanel();
      }
      else if (notificationType === NOTIFICATIONS_ICONS.Bill_Payment_success.type.toUpperCase() || notificationType === NOTIFICATIONS_ICONS.Bill_Payment_failure.type.toUpperCase()) {
        if (this.router.url === url) {
          this.notificationService.notifyBillHistoryUpdate(data?.billNumber);
        }
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: data?.billNumber ?? ''
          }
        });
        this.closeNotificationPanel();
      }

      else if (notificationType === NOTIFICATIONS_ICONS.Meter_MCB_Turned_On.type.toUpperCase()) {
        if (this.router.url === url) {
          this.notificationService.notifymcbTurnOffOnUpdate(data?.macAddress);
        }
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: data?.macAddress ?? ''
          }
        });
        this.closeNotificationPanel();
      }

      else if (notificationType === NOTIFICATIONS_ICONS.Low_Recharge_Alert.type.toUpperCase()) {
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: ''
          }
        });
        this.closeNotificationPanel();
      }
      else if (notificationType === NOTIFICATIONS_ICONS.High_Consumption_Alert_GAS.type.toUpperCase() || notificationType === NOTIFICATIONS_ICONS.High_Consumption_Alert_Water.type.toUpperCase() || notificationType === NOTIFICATIONS_ICONS.High_Consumption_Alert_EB.type.toUpperCase() || notificationType === NOTIFICATIONS_ICONS.High_Consumption_Alert_DG.type.toUpperCase()) {
        if (this.router.url === url) {
          this.notificationService.notifyhighConsumptionnUpdateSubjectUpdate(data?.macAddress);
        }
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: data?.macAddress ?? ''
          }
        });
        this.closeNotificationPanel();
      }
      else {
        // For all other notifications, navigate with state info
        this.router.navigate([url], {
          state: {
            from: 'notifications',
            info: data
          }
        });
        this.closeNotificationPanel();
      }
    } else {
      console.error('Unknown notification type');
    }
  }



  gotoNotificatoinInfo(data: any) {
    this.loaderService.setHeaderLoading(true)
    if (!data.isRead) {
      this.authenticationService.readNotifications(data.id).subscribe({
        next: (response) => {
          this.loaderService.setHeaderLoading(false);
          this.notificationList = this.notificationList.map(notification => {
            // If the notification id matches data.id, set isRead to true, otherwise leave as it is
            if (notification.id === data.id) {
              return { ...notification, isRead: true };
            }
            return notification; // Keep the other notifications unchanged
          });
          // this.notificationList = [];
          this.totalNotifications = this.totalNotifications - 1;

          this.getNavUrl(data);

        },
        error: (error: HttpErrorResponse) => {
          this.loaderService.setHeaderLoading(false)
          this.sharedService.showMessageDialog(
            error.message,
            '',
            SweetAlertIcon.ERROR
          );
          console.error('Error occurred:', error);
        }
      });
    } else {
      this.getNavUrl(data);
    }

  }
  getNotifications() {
    this.store.dispatch(getNotificationList());
  }
  isMenuOpen = false;

  toggleMenu(event: Event) {
    event.stopPropagation();  // Prevents the dropdown from closing
    this.isMenuOpen = !this.isMenuOpen;
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    // this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    console.log('event');
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.loaderService.setHeaderLoading(true);
    this.store.dispatch(logout());
    this.sharedService.walletHistoryIdeclear();
    this.store.dispatch(resetHistoryState());
  }
  getrefreshToken() {
    let requestPayload = {
      token: this.token,
      refreshToken: this.refreshToken,
      deviceType: 'web',
    };
    this.loaderService.setHeaderLoading(true);
    this.store.dispatch(refreshToken({ requestPayload: requestPayload }));
  }

  onSubmit() {
    this.submitted = true;
  }

  url: any = '';
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event?.target?.result;
        console.log(this.url);
      };
    }
  }
  tokenExpiresOn!: Date;

  currentUserData: any;
  exitInstance(): void {
    // Your logic for exiting the instance goes here
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project != null) {
      this.currentUserData.project = null;
      this.currentUserData.projectUtilities = [];
      localStorage.setItem('currentUser', JSON.stringify(this.currentUserData));
    }
    console.log('Exit Instance clicked');
    this.sharedService.updateRole(this.user.actualRole, false);
    this.router.navigate(['/dashboard']);
  }

  getUserInfo() {
    this.store.dispatch(getUserProfile());
    this.sharedService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.activeInstance = user.activeInstance;
      this.userRole = user?.currentUserRoleDetails?.role;
      setTimeout(() => {
        const currentUserString = localStorage.getItem('currentUser');
        this.currentUserData = JSON.parse(currentUserString ?? '');
        // this.totalNotifications = this.currentUserData.notificationCount;
      }, 100);
      console.log(this.currentUserData);
    });
    let getResidentilaUnitById$ = this.store.pipe(select(refreshTokenRes));
    getResidentilaUnitById$.subscribe({
      next: (res) => {
        if (res) {
          this.sesstionexpiresTime = 0;
          this.token = res.token;
          this.refreshToken = res.refreshToken;
          this.tokenExpiresOn = res.tokenExpiresOn;
          this.sessionExpiredTimecalculate();
          console.log('timer Starat');
        }
      }, error(err) {
        console.log(err);
      },
    })

  }


  isOpen = false;

  openNotificationPanel() {
    this.closeNotificationPanel()
    this.getNotifications()
  }

  openViewAllNotificationPanel() {
    this.isOpen = true;
  }

  closeNotificationPanel() {
    this.isOpen = false;
  }
}
