import { BillingType } from './../../shared/constants/enum';
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import MetisMenu from 'metismenujs';
import { EventService } from '../../core/services/event.service';
import { Router, NavigationEnd } from '@angular/router';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { HttpClient } from '@angular/common/http';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../shared/shared.service';
import { json } from 'stream/consumers';
import { LayoutService } from '../../core/services/layout.service';

@Component({
  selector: 'app-rp-sidebar',
  templateUrl: './rp-sidebar.component.html',
  styleUrl: './rp-sidebar.component.scss',
})
export class RpSidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef: any;
  @Input() isCondensed = false;
  menu: any;
  data: any;

  menuItems: MenuItem[] = [];
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @ViewChild('sideMenu') sideMenu!: ElementRef;
  currentUser: any;

  constructor(
    private sharedService: SharedService,
    private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient, private layoutService: LayoutService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this._activateMenuDropdown();
          this._scrollElement();
        }, 500);
      }
    });
  }

  roles: any;
  ngOnInit() {
    this.getUserInfo();
    this._scrollElement();
  }
  billingType: any;
  ConsumerType=BillingType;
  consumerMetrsMenu: any;
  isWalletEnabled: boolean = false;
  isMenuCollapesed: boolean = false;
  isDisplayBillsInvoices: boolean = false;
  getUserInfo() {
    this.sharedService.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
      this.roles = user.currentUserRoleDetails;
      this.billingType = user.project?.billingType;
      //this.consumerMetrsMenu = user.project?.meterMenu;
      this.isWalletEnabled = user?.project?.isWalletEnabled;
      this.isDisplayBillsInvoices = user?.project?.isDisplayOldBills;
      // console.log(this.isWalletEnabled)
      this.initialize();
    });
  }
  ngAfterViewInit(): void {
    this.layoutService.isCollapsed$.subscribe((state) => {
      this.isMenuCollapesed = document.body.classList.contains('vertical-collpsed');

    });
  }

  toggleMenu(event: any) {
    event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName('mm-active').length > 0) {
        const currentPosition = document.getElementsByClassName(
          'mm-active'
        )[0] as HTMLElement;
        const sticky = currentPosition.offsetTop;

        if (sticky > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              sticky + 300;
      }
    }, 300);
  }
  toggleMobileMenu(event: any) {
    localStorage.removeItem('selectedTabIndex');
    // console.log('event', event);
    let filterState!: CompositeFilterDescriptor;
    this.sharedService.clearAllFilters()

    localStorage.removeItem('filter');
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: string) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    let links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths: string[] = [];
    for (let i = 0; i < links.length; i++) {
      // Assert the type of each element in the array as HTMLAnchorElement
      const anchorElement = links[i] as HTMLAnchorElement;
      paths.push(anchorElement.pathname);
    }
    // for (let i = 0; i < links.length; i++) {
    //   paths.push(links[i]['pathname']) ;
    // }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement?.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) {
              childAnchor.classList.add('mm-active');
            }
            if (childDropdown) {
              childDropdown.classList.add('mm-active');
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  }

  // Define functions for each role's filter criteria
  adminFilter(item: any, billingType: string, isWalletEnabled: boolean, isDisplayBillsInvoices: boolean): boolean {
    //  console.log(isWalletEnabled)
    //  console.log(item.isWalletEnabled)
    switch (item.label) {
      case 'Bills':
      case 'Invoices':
        return item.billType === billingType;

      case 'Old Bills':
        return (item.isDisplayBillsInvoices == isDisplayBillsInvoices);
      case 'Wallet':
        return item.isWalletEnabled === isWalletEnabled;
      /*  case 'Unit Rate History':
         if (item.billType === billingType) {
           return true
         } else {
           return false
         } */
      default:
        return true;
    }
  }

  consumerFilter(item: any, billingType: string, isWalletEnabled: boolean, consumerMetrsMenu: boolean): boolean {
    //let isMetrsMenu = true //when isMetrsMenu is true then only show meter details,meter controls in cosumer madule .
    if (item.label === 'Old Bills' && billingType === this.ConsumerType.PREPAID) {
      item.label = 'Old Invoices';
      return true;
    }


    switch (item.label) {
      case 'Wallet':
      case 'Wallet History':
      case 'Recharge History':
        return item.isWalletEnabled === isWalletEnabled;
      /* case 'Unit Rate History':
        if (item.isWalletEnabled === isWalletEnabled && item.billType === billingType) {
          return true
        } else {
          return false
        } */

      // case 'Meter Controlls':
      // return isMetrsMenu === consumerMetrsMenu;
    

      case 'Meters':
      case 'Bills':
      case 'Invoices':
      case 'Bill History':
      case 'Consumption History':
        return item.billType === billingType;
      default:
        return true;
    }
  }
  // Define a mapping of roles to filter functions
  roleFilterMap: { [role: string]: (item: any) => boolean } = {
    'Admin': (item: any) => this.adminFilter(item, this.billingType, this.isWalletEnabled, this.isDisplayBillsInvoices),

    'Consumer': (item: any) => this.consumerFilter(item, this.billingType, this.isWalletEnabled, this.consumerMetrsMenu),
  };
  initialize(): void {
    // Get the filter function for the current role
    let filterFunction = this.roleFilterMap[this.roles.role] || (() => true);

    // Filter menu items based on the role and conditions
    this.menuItems = this.filterMenuItemsByRole(this.roles.role).filter(filterFunction);

    // console.log(this.menuItems);
  }
  /**
   * Initialize
   */
  /*  initialize(): void {
     this.menuItems = this.filterMenuItemsByRole(this.roles.role).filter(
       (item: any) => {
         if (this.roles.role == 'Admin' && item.label == 'Bills') {
           return item.billType === this.billingType;
         }
         if (this.roles.role == 'Consumer' && item.label == 'Wallet') {
           return item.isWalletEnabled == this.isWalletEnabled;
         }
         if (this.roles.role == 'Consumer' && item.label == 'Bills') {
           return item.billType === this.billingType;
         }
         if (this.roles.role == 'Consumer' && item.label == 'Bill History') {
           return item.billType === this.billingType;
         }
         if(
           this.roles.role == 'Consumer' && item.label == 'Consumption History') {
           return item.billType === this.billingType;
         }
           if(this.roles.role == 'Consumer' && item.label == 'Consumption History') {
           return item.billType === this.billingType;
         }
 
         return true;
       }
     );
     console.log(this.menuItems);
   } */

  filterMenuItemsByRole(role: any): any[] {
    return MENU.filter((item: any) => item.roles.includes(role));
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
